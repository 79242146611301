import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { toSlug } from "../helpers";
import Tag from "../components/tag/thumbnail";
import {
  mainBlue,
  mainWhite,
  transHover,
  screen,
} from "../components/variables";

const Wrapper = styled.div`
  min-height: calc(100vh - 100px);
  padding: 70px 0 0 0;
  background: ${mainWhite};

  .title {
    color: ${mainBlue};
    font-weight: 300;
    line-height: 1.32;
    font-size: 1.4rem;
    padding: 0 0 25px 0;
    @media ${screen.xsmall} {
      font-size: 1.95rem;
      padding: 0 0 50px 0;
    }
    @media ${screen.small} {
      font-size: 2.15rem;
    }

    strong {
      font-weight: 700;
    }
  }

  .product-list {
    display: flex;
    margin: 0 -8px;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media ${screen.xsmall} {
      margin: 0 -16px;
    }

    &__each {
      width: calc(50% - 16px);
      background: ${mainWhite};
      margin: 0 8px 28px 8px;
      cursor: pointer;
      align-self: flex-start;
      position: relative;
      @media ${screen.xsmall} {
        margin: 0 16px 32px 16px;
        width: calc(33.33% - 32px);
      }
      @media ${screen.small} {
        width: calc(25% - 32px);
      }
      @media ${screen.medium} {
        width: calc(20% - 32px);
      }

      .img {
        border: 1px #e8e8ea solid;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          background: rgba(32, 33, 33, 0.5);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: ${transHover};
          z-index: 6;
          opacity: 0;
        }

        img {
          transition: all 0.33s ease-out;
        }
      }

      .txt {
        padding: 14px;
        border: 1px #e8e8ea solid;
        margin: 3px 0 0 0;
        position: relative;

        &::after {
          content: "LEARN MORE";
          color: ${mainWhite};
          position: absolute;
          background: ${mainBlue};
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.95rem;
          transition: ${transHover};
          opacity: 0;
        }

        .name {
          color: ${mainBlue};
          font-size: 0.84rem;
          font-weight: 500;
          line-height: 1.4;
          @media ${screen.xsmall} {
            font-size: 0.94rem;
          }
        }

        .specs {
          font-size: 0.84rem;
          margin: 6px 0 0 0;
          @media ${screen.xsmall} {
            font-size: 0.94rem;
          }
        }
      }

      .btn {
        display: block;
        background: ${mainBlue};
        color: ${mainWhite};
        white-space: pre;
        text-align: center;
        font-size: 0.84rem;
        width: 100%;
        padding: 8px 0 4px 0;
        @media ${screen.xsmall} {
          display: none;
        }
      }

      @media ${screen.withCursor} {
        &:hover .txt::after,
        &:hover .img::after {
          opacity: 1;
        }
        &:hover .img img {
          transform: scale(1.06);
        }
      }
    }
  }s
`;

// returned array of products from prismic. a cleaner object
const restructurePrismicProducts = (products) => {
  return products.map((product) => {
    return {
      id: product.node.id,
      name: product.node.data.title.text,
      categories: [
        product.node.data.kind.document &&
          product.node.data.kind.document.data.title.text,
        ...product.node.data.categories.map(
          (cat) => cat.category.document.data.title.text
        ),
        ...product.node.data.industries.map(
          (cat) => cat.industry.document.data.title.text
        ),
        product.node.data.enviropak.document &&
          product.node.data.enviropak.document.data.title.text,
      ],
      images:
        product.node.data.gallery.length !== 0 &&
        product.node.data.gallery[0].image.gatsbyImageData &&
        product.node.data.gallery[0],
      alt:
        product.node.data.gallery.length !== 0 &&
        product.node.data.gallery[0].image.gatsbyImageData &&
        product.node.data.gallery[0].image.alt,
      spec: product.node.data.highlighted_specification_value,
      tag: product.node.data.tag,
    };
  });
};

const SearchPage = ({ data }) => {
  const products = restructurePrismicProducts(data.products.edges);

  const keyword =
    typeof window !== "undefined" &&
    window.location.search.substring(1).replace(/-/g, " ");

  const productResults =
    keyword.length > 2
      ? products.filter((product) => {
          return (
            product.name.toLowerCase().includes(keyword) ||
            product.categories.some(
              (cat) => cat && cat.toLowerCase().includes(keyword)
            )
          );
        })
      : [];

  let feedBack;

  if (productResults.length !== 0) {
    feedBack = (
      <span>
        Search results for <strong>{keyword}</strong>
      </span>
    );
  }

  if (productResults.length === 0) {
    feedBack = (
      <span>
        Your search <strong>{keyword}</strong> <br />
        did not match any product.
      </span>
    );
  }
  if (keyword.length === 0) {
    feedBack = <span>Search field is empty.</span>;
  }

  return (
    <Layout rotatedSquareColour={mainWhite}>
      <Seo title="Search" />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="title">{feedBack}</h1>
          {productResults.length !== 0 && (
            <ul className="product-list">
              {productResults.map((item, i) => (
                <li
                  className="product-list__each"
                  onClick={() => {
                    navigate(`/products/${toSlug(item.name)}`);
                  }}
                  key={item.id + i}
                >
                  <figure className="img">
                    {item.images && item.images.image.gatsbyImageData ? (
                      <GatsbyImage
                        image={item.images.image.gatsbyImageData}
                        alt={item.alt || "Featured"}
                      />
                    ) : (
                      <StaticImage
                        src="../images/fallback-gallery.jpg"
                        alt="Thumbnail"
                      />
                    )}
                  </figure>
                  <div className="txt">
                    <h3 className="name">{item.name}</h3>
                    <h3 className="specs">{item.spec}</h3>
                  </div>
                  <button className="btn">LEARN MORE</button>
                  <Tag label={item.tag} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </Wrapper>
    </Layout>
  );
};

export default SearchPage;

export const dataQuery = graphql`
  {
    products: allPrismicProduct {
      edges {
        node {
          id
          data {
            title {
              text
            }
            tag
            gallery {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
            }
            highlighted_specification_value
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            enviropak {
              document {
                ... on PrismicEnviropak {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            industries {
              industry {
                document {
                  ... on PrismicIndustry {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            kind {
              id
              document {
                ... on PrismicKind {
                  id
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
